.chat {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f2f2f2;
}

.content-wrapper {
  display: flex;
  flex: 1;
  margin-top: 47px;
}

.menu-column {
  flex: 1;
  background-color: #f2f2f2;
  overflow: scroll;
  max-height: calc(100vh - 50px);
}

.topics-column {
  flex: 2;
  background-color: #ffffff;
  padding: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 50px); /* Ajusta la altura máxima según sea necesario */
}

.elementos-lista {
  list-style: none;
  padding: 0;
  margin: 0;
}

.elemento {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.new-conversation-button {
  margin-right: 10px;
}

.logout-button {
  margin-left: 10px;
}
  
  .chat-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .chat-form button:hover {
    background-color: #0056b3;
  }

  /* Media query para pantallas con un ancho máximo de 600px */
  @media (max-width: 600px) {
    .chat {
      width: 100%;
      height: 100%;
      max-height: none;
      display: flex;
      flex-direction: column;
    }
  
    .chat-messages {
      padding-top: 95px; /* Añade esta línea */
      flex-grow: 1;
      margin-bottom: 8px;
      height: calc(100vh - 25px);
    }
  
    .chat-form {
      margin-bottom: 8px;
    }
  }  


.new-conversation-button {
  background-color: #ffffff;
  color: #4d574d;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px 2px;
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.new-conversation-button:hover {
  background-color: #4caf50;
  color: white;
}

.chat-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #323332;
  padding: 4px 20px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.chat-header-title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}



.listado-container {
  margin: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.elementos-lista {
  list-style-type: none;
  padding: 0;
}

.elemento {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topic-buttons {
  display: flex;
  margin-top: 14px;
}

.edit-button,
.delete-button {
  background: none;
  cursor: pointer;
  margin-left: 10px;
  border: solid 1px #d4d4d4;
}
.delete-button {
  color: rgb(218, 28, 28);
}

.edit-button:hover,
.delete-button:hover {
  color: rgb(218, 28, 28);
}


/*# FORM */

.form-topic {
  margin-right: 20px;
  padding: 20px;
}

.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type='text'],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.form-check {
  margin-bottom: 20px;
}

.form-check-input {
  margin-right: 10px;
}

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  background-color: #0056b3;
}

.error {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #f2dede;
  border-radius: 4px;
  color: #a94442;
  background-color: #f2dede;
  text-align: center;
}

.message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.message.visible {
  opacity: 1;
}

.elemento {
  display: flex;
  align-items: center;
}

.topic-text {
  flex: 1;
}

.topic-image {
  margin-left: auto;
  width: 80px;
  padding-left: 10px;
}