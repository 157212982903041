.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-container  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #efefef;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px gray;
  }
  
  .login-container h1 {
    margin-bottom: 20px;
  }
  
  .form-control {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .login-container label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .login-container input[type="text"],
  .login-container input[type="password"] {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid gray;
    width: 300px;
  }
  
  .login-container button[type="submit"] {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  .login-container .error {
    color: #f44336;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
  }