.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a; /* Color de fondo oscuro */
  }

  /* Media query para pantallas con un ancho máximo de 600px */
@media (max-width: 600px) {
    .App {
        padding: 0;
        height: 100%;
    }
  }